<template>
  <div class="complaint">
    <Header class="hide" />
    <Headers class="dispy" style="float:inherit" />
    <BannerImage rulename="scenicspot_banner" />
    <div class="w">
      <div class="lianxi">
        <div class="hide">
        <div class="tuo">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }"
              ><i class="el-icon-s-home"></i>首页</el-breadcrumb-item
            >
            <el-breadcrumb-item v-for="(item, index) in crumblist" :key="index">
              <span v-if="!item.url">{{ item.name }}</span>
              <router-link v-else :to="item.url">{{ item.name }}</router-link>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        </div>
        <div class="info">
          <el-row>
            <el-col :md="12">
              <div class="left">
                <img src="@/assets/image/main/zixun.png" alt="" />
                <div class="doc">
                  <p class="title">咨询热线</p>
                  <p class="tiem">7X24小时</p>
                  <p class="phone">{{ timeinfo.theValue }}</p>
                </div>
              </div>
            </el-col>
            <el-col :md="12">
              <div class="right">
                <img src="@/assets/image/main/tousu.png" alt="" />
                <div class="doc">
                  <p class="title">投诉热线</p>
                  <p class="tiem">7X24小时</p>
                  <p class="phone">{{ complaint.theValue }}</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="fubu">
        <div class="details">
          <div class="title">
            我要投诉
          </div>
          <el-divider></el-divider>
          <div class="hide">
            <div class="from">
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="姓名:" prop="realname">
                      <el-input
                        v-model="ruleForm.realname"
                        style="width: 402px;"
                        class="opp"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="性别:" prop="sex">
                      <el-radio-group v-model="ruleForm.sex" size="small">
                        <el-radio-button label="1">男</el-radio-button>
                        <el-radio-button label="2">女</el-radio-button>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="手机电话:" prop="phone">
                      <el-input
                        v-model="ruleForm.phone"
                        style="width: 402px;"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="被投诉方:" prop="compSide">
                      <el-input
                        v-model="ruleForm.compSide"
                        style="width: 402px;"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="投诉理由:" prop="compReason">
                      <el-input
                        type="textarea"
                        :rows="2"
                        v-model="ruleForm.compReason"
                        maxlength="500"
                        show-word-limit
                        style="width: 987px;"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="上传凭证:" prop="compUpload">
                      <PictureMultiple
                        class="picmult"
                        @change="picHandler"
                        @deleteChange="deleteHandler"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item>
                      <el-button
                        type="primary"
                        @click="submitForm('ruleForm')"
                        class="tijiao"
                        >提交信息</el-button
                      >
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </div>

          <div class="dispy">
            <div class="from">
              <el-form
                :label-position="labelPosition"
                :model="ruleForm"
                :rules="rules"
                ref="wapruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="姓名:" prop="realname">
                      <el-input
                        v-model="ruleForm.realname"
                        style="width: 402px;"
                        class="opp"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="性别:" prop="sex">
                      <el-radio-group v-model="ruleForm.sex" size="small">
                        <el-radio-button label="1">男</el-radio-button>
                        <el-radio-button label="2">女</el-radio-button>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="手机电话:" prop="phone">
                      <el-input
                        v-model="ruleForm.phone"
                        style="width: 402px;"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="被投诉方:" prop="compSide">
                      <el-input
                        v-model="ruleForm.compSide"
                        style="width: 402px;"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="投诉理由:" prop="compReason">
                      <el-input
                        type="textarea"
                        :rows="2"
                        v-model="ruleForm.compReason"
                        maxlength="500"
                        show-word-limit
                        style="width: 987px;"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="上传凭证:" prop="compUpload">
                      <PictureMultiple
                        class="picmult"
                        @change="picHandler"
                        @deleteChange="deleteHandler"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item>
                      <el-button
                        type="primary"
                        @click="submitwapForm('wapruleForm')"
                        class="tijiao"
                        >提交信息</el-button
                      >
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import BannerImage from "@/components/main/BannerImage";
import PictureMultiple from "@/components/PictureMultiple";
import Breadcrumb from "@/components/main/Breadcrumb";
import Header from "@/components/main/Header";
import Headers from "@/components/wap/Headers";
import Footer from "@/components/main/Footer";
import Pagi from "@/components/Pagi";
import { loadConfig, loadPictureConfig } from "@/api/api";
import { getAction, postAction, putAction } from "@/api/manage.js";
export default {
  created() {
    this.loadData();
  },
  components: {
    Header,
    Footer,
    Pagi,
    Breadcrumb,
    PictureMultiple,
    BannerImage,
    Headers,
  },
  data() {
    return {
       labelPosition: 'top',
      crumbdata: [
        { name: "首页", url: "/" },
        { name: "投诉与建议", url: "/complaint" },
      ],
      timeinfo: "",
      complaint: "",
      ruleForm: {
        realname: "",
        phone: "",
        sex: "",
        compSide: "",
        compReason: "",
        compUpload: "",
      },
      rules: {
        realname: [{ required: true, message: "请输入姓名", trigger: "change" }],
        phone: [{ required: true, message: "请输入电话", trigger: "change" }],
        compSide: [
          { required: true, message: "请输入被投诉方", trigger: "change" },
        ],
        sex:[
          { required: true, message: "请选择姓名", trigger: "change" },
        ],
        compReason: [
          { required: true, message: "请输入投诉理由", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = this.ruleForm;
          // 添加
          postAction("/complaint/tourComplaint/add", data).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.$router.push('ComplaintList')
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    submitwapForm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = this.ruleForm;
          // 添加
          postAction("/complaint/tourComplaint/add", data).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.$router.push('ComplaintList')
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    loadData() {
      loadConfig({
        rule: "adv_tel",
      }).then((res) => {
        if (res.success) {
          this.timeinfo = res.result;
        }
      });
      loadConfig({
        rule: "complaint_tel",
      }).then((res) => {
        if (res.success) {
          this.complaint = res.result;
        }
      });
    },
    picHandler(val) {
      this.ruleForm.compUpload += val + ",";
    },
    deleteHandler(val) {
      // this.form.pic.indexOf(val)
    },
  },
};
</script>
<style lang="scss" scoped>
.complaint {
  .bars {
    width: 100%;
  }
  background: url(/img/white-bg.png) no-repeat fixed top left;
  .lianxi {
    background-color: #ffffff;
    margin-top: 30px;
    margin-bottom: 25px;
    padding-top: 16px;
    .tuo {
      margin-left: 18px;
    }
    .info {
      margin-top: 57px;
      padding-bottom: 30px;
      margin-left: 136px;
      .left {
        width: 394px;
        height: 176px;
        background-color: #6EB40029;
        border-radius: 4px;
        padding-left: 87px;
        img {
          margin-top: 42px;
        }
        .doc {
          float: right;
          margin-top: 39px;
          margin-right: 139px;
          .title {
            font-family: MicrosoftYaHei;
            font-size: 22px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 48px;
            letter-spacing: 0px;
            color: #333333;
          }
          .tiem {
            font-family: MicrosoftYaHei;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 29px;
            letter-spacing: 0px;
            color: #666666;
          }
          .phone {
            font-family: MicrosoftYaHei;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #666666;
          }
        }
      }
      .right {
        width: 394px;
        height: 176px;
        background-color: #fff2eb;
        border-radius: 4px;
        padding-left: 87px;
        img {
          margin-top: 42px;
        }
        .doc {
          float: right;
          margin-top: 39px;
          margin-right: 139px;
          .title {
            font-family: MicrosoftYaHei;
            font-size: 22px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 48px;
            letter-spacing: 0px;
            color: #333333;
          }
          .tiem {
            font-family: MicrosoftYaHei;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 29px;
            letter-spacing: 0px;
            color: #666666;
          }
          .phone {
            font-family: MicrosoftYaHei;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #666666;
          }
        }
      }
    }
  }
  .fubu {
    .details {
      background-color: #ffffff;
      margin-bottom: 25px;
      padding-top: 32px;
      padding-bottom: 76px;
      .title {
        font-family: MicrosoftYaHei;
        font-size: 22px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #999999;
        margin-left: 22px;
        padding-bottom: 17px;
      }
      .el-divider--horizontal {
        width: 96%;
        margin: 0 0 0 16px;
      }
      .from {
        margin-top: 56px;
        .shangchaun {
          margin-left: 22px;
        }
        .tijiao {
          width: 212px;
          height: 44px;
          background-color: #ff7f3c;
          border-color: #ff7f3c;
          margin-left: 365px;
          margin-top: 45px;
        }
      }
    }
    ::v-deep.el-textarea__inner {
      min-height: 140px !important ;
    }
  }
  .picmult {
    margin-top: 20px;
  }
  ::v-deep.el-radio-button__orig-radio:checked + .el-radio-button__inner {
    border-color: #6EB400;
    background-color: #6EB400;
  }
}
</style>
